import React from 'react'
import QRcode from './Components/QRCode'
import Dummy from './Components/Dummy';
import { BrowserRouter,Routes,Route } from 'react-router-dom';

function App(){
  return (
    <BrowserRouter>
    <Routes>
      <Route path='/' element={<QRcode/>}/>
      <Route path='/dummy' element={<Dummy/>}/>
    </Routes>
    </BrowserRouter>
  )
}

export default App